import gql from "graphql-tag";

export const uploadForm = gql`
    mutation uploadForm($description: String, $person: uploadPersonInput!, $file: Upload, $creator: uploadCreatorInput) {
        uploadForm(input: {description: $description, person: $person, file: $file}, creator: $creator) {
            success
        }
    }
`;

export const login = gql`
    mutation login($code: String!, $state: String! ){
        login(code: $code, state: $state){
            success,
            errors,
            access_token,
            token_type,
            name,
            state
        }
    }
`;
