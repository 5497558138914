<template>
  <div id="app">
    <header class="header-bar">
      <div class="title-bar">
        <router-link :to="{name: 'main'}">
          <img class="logo" src="./assets/logo.svg" />
        </router-link>
        <div class="title-bar-block">
          <router-link :to="{name: 'main'}">
            <h2>{{ $t("title") }}</h2>
          </router-link>
        </div>
        <div v-if="$store.getters['account/username']" class="login">{{ $store.getters["account/username"] }}</div>
        <div v-if="$store.getters['account/username']" class="logout">
          <span class="nowrap">
            <span style="margin-right: 10px">|</span>
            <a :href="'https://elogin.put.poznan.pl/?do=Logout&system=' + eLoginClientId" @click="$store.commit('account/logout')">{{ $t("logout") }}</a>
          </span>
        </div>
      </div>
    </header>
    <router-view :class="notFoundClass" class="contents" />
    <footer class="footer-bar">
      <div>
        <div class="copyright">
          <b-icon icon="copyright" />&nbsp;{{ $t("university-name") }} {{ new Date().getFullYear() }}<br />
          <a href="https://www.put.poznan.pl" target="_blank" rel="noopener">www.put.poznan.pl</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: "App",
    computed: {
      notFoundClass() {
        return [this.$route.name === "not-found" ? "not-found" : ""]
      }
    }
  }
</script>

<style lang="scss">
  @import "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css";
  @import "https://fonts.googleapis.com/css?family=Exo+2:400,100,100italic,200,200italic,300,300italic,400italic,500,500italic,600,600italic,700,700italic,800,900,900italic,800italic&subset=latin,latin-ext";
  @import "variables";
  @import "~bulma";
  @import "~buefy/src/scss/buefy";

  html {
    overflow-y: auto;
  }

  body {
    font-family: "Exo 2", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    min-height: 100%;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
    color: $dark;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  .nowrap {
    white-space: nowrap;
  }

  #app {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;
  }

  .header-bar {
    background-color: $light;
    padding-top: 36px;
    color: white;
    line-height: 1.6;
    font-weight: 400;
    flex: 1 100%;
  }

  .title-bar {
    display: flex;
    align-items: center;
    background-color: $dark;
    height: 140px;
    padding: 20px 0;
  }

  .title-bar > a {
    margin-left: 180px;
  }

  .title-bar .logo {
    max-width: 100%;
    max-height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .title-bar-block {
    padding: 0 30px;
  }

  .title-bar-block a {
    color: white;
  }

  .title-bar-block a:hover {
    text-decoration: none;
  }

  .title-bar h2 {
    margin: 0;
  }

  .title-bar h6 {
    margin: 0;
  }

  .deleted {
    text-decoration: line-through;
    color: #ff3860;
  }

  .new,
  .toverify,
  .verified,
  .del {
    display: none;
  }

  .tab-contents .content {
    display: none;
  }
  .tab-contents .content.is-active {
    display: block;
  }

  .login {
    margin-right: 5px;
    margin-left: auto;
  }

  .logout {
    margin-right: 180px;
    margin-left: 10px;
  }

  .logout a {
    color: white;
  }

  .contents {
    flex: 1;
    margin: 0 !important;
    padding: 20px 180px;
    overflow-y: auto;
    height: calc(100vh - 326px);
  }

  .contents > div > h3:first-of-type {
    margin-left: 14px;
  }

  .tages {
    font-size: 1rem;
    background-color: #d7490c;
    font-style: inherit;
    font-weight: inherit;
    color: #fff;
    align-items: center;
    border-radius: 4px;
    padding-left: 0.75em;
    padding-right: 0.75em;
    height: 2em;
  }

  .error {
    color: darkred;
    font-size: smaller;
  }

  .not-found {
    background-image: url("assets/404.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .footer-bar {
    background-color: $dark;
    color: white;
    height: 150px;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 1 100%;
    padding: 100px 100px 0 100px;
    background-image: url("assets/watermark.png");
    background-position: center center;
    background-repeat: no-repeat;
  }

  .footer-bar a {
    color: white;
  }

  .footer-bar h3 {
    color: white;
  }

  .footer-bar > div {
    position: relative;
  }

  .footer-bar .copyright {
    position: absolute;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    bottom: 0;
    text-align: center;
  }

  .tabs li.is-active a {
    color: $primary !important;
    border-bottom-color: $primary !important;
  }

  .b-table .table .chevron-cell > a {
    color: $primary !important;
  }

  .title-bar .logo {
    animation-name: rotate;
    animation-duration: 6s;
    animation-iteration-count: 2;
    animation-timing-function: ease-in-out;
    animation-delay: 3s;
  }

  @keyframes rotate {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(0deg);
    }
    60% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

  @media only screen and (max-width: 1023px) {
    .title-bar > a {
      margin-left: 50px;
    }
    .logout {
      margin-right: 50px;
    }
    .contents {
      padding: 20px 12px;
    }
    .footer-bar {
      padding-left: 50px;
      padding-right: 50px;
    }
    .tages {
      font-size: 1rem;
      background-color: #d7490c;
      font-style: inherit;
      font-weight: inherit;
      color: #fff;
      align-items: center;
      border-radius: 4px;
      padding-left: 0.75em;
      padding-right: 0.75em;
      height: 3em;
    }
  }

  @media only screen and (max-width: 768px) {
    .tages {
      font-size: 1rem;
      background-color: #d7490c;
      font-style: inherit;
      font-weight: inherit;
      color: #fff;
      align-items: center;
      border-radius: 4px;
      padding-left: 0.75em;
      padding-right: 0.75em;
      height: 6em;
    }
    h1 {
      font-size: 1.8rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.2rem;
    }
    h6 {
      font-size: 0.7rem;
    }
    .header-bar {
      padding-top: 20px;
    }
    .title-bar {
      height: 90px;
      padding: 10px 0;
    }
    .title-bar {
      padding-left: 20px;
    }
    .title-bar > a {
      display: none;
    }
    .title-bar-block a h6 {
      display: none;
    }
    .login {
      text-align: right;
    }
    .contents {
      height: calc(100vh - 230px);
    }
    .footer-bar {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      height: 120px;
    }
    .footer-bar .copyright {
      display: none;
    }
  }

  @media only screen and (max-height: 768px) and (orientation: landscape) {
    .contents {
      height: 100%;
    }
    .footer-bar {
      position: static;
    }
  }
</style>
