<template>
  <div></div>
</template>

<script>
  import {eLoginClientId} from "@/config"

  export default {
    name: "Login",
    async mounted() {
      if (this.$route.query.code) {
        await this.$store.dispatch("account/login", {code: this.$route.query.code, state: this.$route.query.state})
        window.location.href = this.$route.query.state
      } else {
        const scope = "ekonto.user"
        window.location.href = "https://elogin.put.poznan.pl/?do=OAuth&response_type=code&client_id=" + eLoginClientId + "&scope=" + scope + "&state=/"
      }
    }
  }
</script>

<style scoped></style>
