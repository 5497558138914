<template>
  <div>{{ $t("redirect") }}&nbsp;<b-icon class="cog" icon="cog" type="is-primary" /></div>
</template>

<script>
  import {eLoginClientId} from "@/config"

  export default {
    name: "Redirect",
    mounted() {
      const scope = "ekonto.user"
      const state = encodeURIComponent(window.location.href)
      window.location.href = "https://elogin.put.poznan.pl/?do=OAuth&response_type=code&client_id=" + eLoginClientId + "&scope=" + scope + "&state=" + state
    }
  }
</script>

<style scoped>
  .cog {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
