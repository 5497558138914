import NotFound from "@/pages/NotFound"
import Main from "@/pages/Main"
import Login from "@/pages/Login"

export default [
  {
    path: "/",
    name: "main",
    component: Main
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound
  },
  {
    path: "/login",
    name: "login",
    component: Login
  }
]
