<template>
  <section id="form">
    <div>
      <div class="panel">
        <div class="panel-block">
          <p class="has-text-centered is-size-5"
            >Szanowni Państwo,<br />
            zgodnie z zapisami <a href="https://bip.put.poznan.pl/pl/2022/zarzadzenie-nr-28-z-dnia-20-czerwca-2022-r">Zarządzenia nr 28 z dnia 20 czerwca 2022 r.</a> jeżeli czujecie się
            Państwo poddani działaniom dyskryminacyjnym lub molestowaniu w związku z zatrudnieniem bądź kształceniem na Politechnice Poznańskiej macie Państwo prawo złożyć skargę na
            takie zachowanie. Skarga zostanie rozpatrzona niezwłocznie przez Rzecznika ds. równości, a dalsze postępowanie będzie uzależnione od wyników badania zgłoszenia.</p
          >
        </div>
      </div>
      <h5 class="has-text-centered">Zgłoszenie nieprawidłowości może nastąpić poprzez odpowiedź na poniższe pytania gwarantując usprawnienie jej przetworzenia.</h5>
      <form @submit.prevent="send()">
        <div class="block mt-5">
          <div class="box">
              <p class="has-text-centered is-size-5 is-primary tages">Proszę podać dane osoby dopuszczającej się zachowań, które można uznać za dyskryminację i/lub molestowanie:</p>
            <br />
            <b-field label="Imię:">
              <b-input v-model="person.firstName" placeholder="Imię" required />
            </b-field>
            <b-field label="Nazwisko:">
              <b-input v-model="person.lastName" placeholder="Nazwisko" required />
            </b-field>
              <b-field label="Szczegółowy opis zachowań i towarzyszących mu okoliczności:">
                <b-input v-model="description" type="textarea" required />
              </b-field>
              <b-field label="Załączniki potwierdzające ww. zachowania (opcjonalnie):">
                <b-upload v-model="files" multiple drag-drop expanded @input="uploadFilesChange">
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                      </p>
                      <p>Kliknij w pole by wybrać pliki lub przeciągnij i upuść je tutaj</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
              <div class="tags">
                <span v-for="(file, index) in files" :key="index" class="tag is-primary">
                  {{ file.name }}
                  <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                </span>
              </div>
            </div>
          <div class="box">
            <p class="has-text-centered is-size-5 is-primary tages">Opcjonalne dane kontaktowe zgłaszającego:</p>
            <br />
            <b-field label="Numer telefonu:">
              <b-input v-model="creator.number" placeholder="Numer telefonu" />
            </b-field>
          </div>
        </div>
        <div>
          <div class="level-right">
            <b-button type="is-primary" icon-left="email-send" native-type="submit">Wyślij zgłoszenie</b-button>
          </div>
          <div class="box mt-5 pt-5b has-text-centered">
            Administratorem danych jest Politechnika Poznańska, pl. M. Skłodowskiej – Curie 5, 60-965 Poznań.<br />Państwa dane przetwarzane będą w celu przyjęcia i rozpatrzenia
            zgłoszenia dotyczącego dyskryminacji i/lub molestowania. Pozostałe informacje dotyczące przetwarzania Państwa danych osobowych znajdują się na stronie
            <a href="https://put.poznan.pl/ochrona-danych-osobowych">https://put.poznan.pl/ochrona-danych-osobowych</a>.
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
  import {uploadForm} from "@/graphql/mutations"

  export default {
    name: "Form",
    data() {
      return {
        description: null,
        files: null,
        person: {
          firstName: null,
          lastName: null,
        },
        creator: {
          number: null
        },
        number: null
      }
    },
    methods: {
      uploadFilesChange(dropFiles) {
        this.files = dropFiles
      },
      send() {
        console.log(this.files)
        this.$apollo
          .mutate({
            mutation: uploadForm,
            variables: {
              description: this.description,
              files: this.files,
              person: {
                firstName: this.person.firstName,
                lastName: this.person.lastName,
              },
              creator: {
                number: this.creator.number
              }
            }
          })
          .then(() => {
            this.$swal(this.$t("university-name"), this.$t("success-send"), "success")
          })
          .catch(error => {
            this.$swal(this.$t("university-name"), this.$t("error") + "<br /><br /><small>" + error + "</small>", "error")
          })
      },
      deleteDropFile(index) {
        this.files.splice(index, 1)
      }
    }
  }
</script>

<style scoped></style>
