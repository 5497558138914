<template>
  <div>
    <Redirect v-if="!$store.getters['account/username']" />
    <Form v-else />
  </div>
</template>

<script>
  import Redirect from "@/components/Redirect"
  import Form from "@/components/Form"

  export default {
    name: "Main",
    components: {
      Redirect,
      Form
    }
  }
</script>

<style scoped></style>
