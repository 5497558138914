import {ApolloLink} from "apollo-link"
import {onError} from "apollo-link-error"
import {setContext} from "apollo-link-context"
import {createUploadLink} from "apollo-upload-client"
import {ApolloClient, InMemoryCache} from "@apollo/client/core"
import {apiURL, eLoginClientId} from "@/config"

const linkError = onError(({graphQLErrors, operation, forward}) => {
  if (graphQLErrors && graphQLErrors[0].extensions?.code === "JWT_SIGNATURE_EXPIRED") {
    const oldHeaders = operation.getContext().headers
    sessionStorage.setItem("access_token", graphQLErrors[0].extensions.access_token)
    sessionStorage.setItem("token_type", graphQLErrors[0].extensions.token_type)
    operation.setContext({
      headers: {
        ...oldHeaders,
        Authorization: `${graphQLErrors[0].extensions.token_type} ${graphQLErrors[0].extensions.access_token}`
      }
    })
    return forward(operation)
  }
  if (graphQLErrors && ["JWT_UNAUTHENTICATED", "JWT_MISSING_TOKEN", "JWT_REFRESH_SIGNATURE_EXPIRED"].includes(graphQLErrors[0].extensions?.code)) {
    const scope = "ekonto.user"
    const state = encodeURIComponent(window.location.href)
    window.location.href = "https://elogin.put.poznan.pl/?do=OAuth&response_type=code&client_id=" + eLoginClientId + "&scope=" + scope + "&state=" + state
  }
})

const linkTokenHeader = setContext(async (_, {headers}) => {
  if (sessionStorage.getItem("token_type") && sessionStorage.getItem("access_token")) {
    return {
      headers: {
        ...headers,
        Authorization: `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`
      }
    }
  } else {
    return {
      headers: {
        ...headers
      }
    }
  }
})

const httpLink = createUploadLink({
  uri: apiURL
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([linkError, linkTokenHeader, httpLink])
})
