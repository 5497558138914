import Vue from "vue"
import App from "./App.vue"
import VueRouter from "vue-router"
import VueI18n from "vue-i18n"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import Buefy from "buefy"
import {eLoginClientId, sentryDSN} from "./config"
import routes from "./routes"
import {apolloClient} from "./vue-apollo"
import {login} from "./graphql/mutations"
import VueApollo from "vue-apollo"
import VueSweetAlert from "vue-sweetalert"
import * as Sentry from "@sentry/vue"
import {Integrations} from "@sentry/tracing"

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(Buefy)
Vue.use(VueApollo)
Vue.use(VueSweetAlert)
Vue.use(VueI18n)

Vue.config.productionTip = false
Vue.prototype.eLoginClientId = eLoginClientId

const router = new VueRouter({routes})

const account = {
  namespaced: true,
  state: {
    username: null
  },
  getters: {
    username: account => account.username
  },
  mutations: {
    login(state, payload) {
      state.username = payload.name
    },
    logout(state) {
      state.username = null
    }
  },
  actions: {
    async login({commit}, authDetails) {
      try {
        const {data} = await apolloClient.mutate({mutation: login, variables: {...authDetails}})
        commit("login", data.login)
        sessionStorage.setItem("access_token", data.login.access_token)
        sessionStorage.setItem("token_type", data.login.token_type)
      } catch (error) {
        console.error(error)
      }
    },
    async logout({commit}) {
      commit("logout")
      sessionStorage.removeItem("access_token")
      sessionStorage.removeItem("token_type")
    }
  }
}

function loadLocaleMessages() {
  const locales = require.context("./i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: navigator.language.substring(0, 2),
  fallbackLocale: "en",
  messages: loadLocaleMessages()
})

const vuexSessionStorage = new VuexPersistence({
  key: "account",
  storage: window.sessionStorage,
  modules: ["account"]
})

const store = new Vuex.Store({
  state: {},
  getters: {},
  modules: {
    account: account
  },
  plugins: [vuexSessionStorage.plugin]
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

if (sentryDSN) {
  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    dsn: sentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "nierowne-traktowanie.put.poznan.pl", /^\//]
      })
    ],
    tracesSampleRate: 1.0
  })
}

new Vue({
  store,
  router,
  i18n,
  apolloProvider,
  render: h => h(App)
}).$mount("#app")
